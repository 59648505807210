
import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/views/Login.vue'
import Catalog from '@/views/Catalog.vue'

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
		path: '/catalog',
		name: 'Catalog',
		component: Catalog
	},
	{
		path: '/events',
		name: 'Events',
		component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue')
	},
	{
		path: '/reception',
		name: 'Reception',
		component: () => import(/* webpackChunkName: "events" */ '../views/Reception.vue')
	},
	// {
	// 	path: '/scan',
	// 	name: 'Scan',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/Scan.vue'),
	// 	beforeEnter: (to, from, next) => operatorRoute(to, from, next)
	// },
	{
		path: '/workers',
		name: 'Workers',
		component: () => import(/* webpackChunkName: "workers" */ '../views/Workers.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/workers/:id',
		name: 'WorkerDetail',
		component: () => import(/* webpackChunkName: "workerdetail" */ '../views/WorkerDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Сотрудники',
					link: '/workers'
				}
			]
		}
	},
	{
		path: '/earners',
		name: 'Earners',
		component: () => import(/* webpackChunkName: "earners" */ '../views/Earners.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/earners/:id',
		name: 'EarnerDetail',
		component: () => import(/* webpackChunkName: "earnerdetail" */ '../views/EarnerDetail'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Добытчики',
					link: '/earners'
				}
			]
		}
	},
	{
		path: '/ships',
		name: 'Ships',
		component: () => import(/* webpackChunkName: "ships" */ '../views/Ships.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/ships/:id',
		name: 'ShipDetail',
		component: () => import(/* webpackChunkName: "shipdetail" */ '../views/ShipDetail'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Плашкоуты',
					link: '/ships'
				}
			]
		}
	},
	{
		path: '/transports',
		name: 'Transports',
		component: () => import(/* webpackChunkName: "transports" */ '../views/Transports.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/transports/:id',
		name: 'TransportDetail',
		component: () => import(/* webpackChunkName: "transportdetail" */ '../views/TransportDetail'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'ПТС',
					link: '/transports'
				}
			]
		}
	},
	{
		path: '/factories',
		name: 'Factories',
		component: () => import(/* webpackChunkName: "factories" */ '../views/Factories.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/factories/:id',
		name: 'FactoryDetail',
		component: () => import(/* webpackChunkName: "factorydetail" */ '../views/FactoryDetail'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Заводы',
					link: '/factories'
				}
			]
		}
	},
	{
		path: '/reports',
		name: 'Reports',
		component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
		beforeEnter: (to, from, next) => factoryRoute(to, from, next)
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
	},
	{
		path: '/organizations',
		name: 'Organizations',
		component: () => import(/* webpackChunkName: "organizations" */ '@/views/Organizations.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		},
		beforeEnter: (to, from, next) => factoryRoute(to, from, next)
	},
	{
		path: '/organizations/:id',
		name: 'OrganizationDetail',
		component: () => import(/* webpackChunkName: "organizationdetail" */ '@/views/OrganizationDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Организации',
					link: '/organizations'
				}
			]
		},
		beforeEnter: (to, from, next) => factoryRoute(to, from, next)
	},
	{
		path: '/organizations/new',
		name: 'OrganizationNew',
		component: () => import(/* webpackChunkName: "organizationnew" */ '@/views/OrganizationNew.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Организации',
					link: '/organizations'
				}
			]
		},
		beforeEnter: (to, from, next) => factoryRoute(to, from, next)
	},
	{
		path: '/licenses',
		name: 'Licenses',
		component: () => import(/* webpackChunkName: "licenses" */ '@/views/Licenses.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/licenses/:id',
		name: 'LicenseDetail',
		component: () => import(/* webpackChunkName: "licensedetail" */ '@/views/LicenseDetail.vue'),
		props: ({ params: { id } }) => ({ id }),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Разрешения на добычу',
					link: '/licenses'
				}
			]
		}
	},
	{
		path: '/licenses/new',
		name: 'LicenseNew',
		component: () => import(/* webpackChunkName: "licensedetail" */ '@/views/LicenseDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Разрешения на добычу',
					link: '/licenses'
				}
			]
		}
	},
	{
		path: '/tariffs',
		name: 'Tariffs',
		component: () => import(/* webpackChunkName: "tariffs" */ '@/views/Tariffs.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/tariffs/:id',
		name: 'TariffsDetail',
		component: () => import(/* webpackChunkName: "tariffsdetail" */ '@/views/TariffsDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Тарифы',
					link: '/tariffs'
				}
			]
		}
	},
	{
		path: '/tariffs/new',
		name: 'TariffsNew',
		component: () => import(/* webpackChunkName: "tariffsdetail" */ '@/views/TariffsDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Тарифы',
					link: '/tariffs'
				}
			]
		}
	},
	{
		path: '/boxes',
		name: 'Boxes',
		component: () => import(/* webpackChunkName: "boxes" */ '@/views/Boxes.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/boxes/:id',
		name: 'BoxesDetail',
		component: () => import(/* webpackChunkName: "boxesdetail" */ '@/views/BoxesDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Ящики',
					link: '/boxes'
				}
			]
		}
	},
	{
		path: '/boxes/new',
		name: 'BoxesNew',
		component: () => import(/* webpackChunkName: "boxesdetail" */ '@/views/BoxesDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Ящики',
					link: '/boxes'
				}
			]
		}
	},
	{
		path: '/regions',
		name: 'Regions',
		component: () => import(/* webpackChunkName: "regions" */ '@/views/Regions.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/regions/:id',
		name: 'RegionDetail',
		component: () => import(/* webpackChunkName: "regiondetail" */ '@/views/RegionDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Рыболовные участки',
					link: '/regions'
				}
			]
		}
	},
	{
		path: '/regions/new',
		name: 'RegionNew',
		component: () => import(/* webpackChunkName: "regiondetail" */ '@/views/RegionDetail.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				},
				{
					title: 'Рыболовные участки',
					link: '/regions'
				}
			]
		}
	},
	{
		path: '/cams',
		name: 'Cams',
		component: () => import(/* webpackChunkName: "cams" */ '@/views/Cams.vue'),
		meta: {
			breadcrumb: [
				{
					title: 'Справочники',
					link: '/catalog'
				}
			]
		}
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
	}
]

// const adminRoute = (to, from, next) => {
// 	if (roles.value.admin) next()
// 	else next({ name: 'Catalog' })
// }

// const operatorRoute = (to, from, next) => {
// 	const { operator, admin } = roles.value
// 	if (operator || admin) next()
// 	else next({ name: 'Scan' })
// }

const factoryRoute = (to, from, next) => {
	const { roles } = JSON.parse(localStorage.account)
	if (to.name === 'Reports' && roles.WEB_RECEPTION) next()
	if (roles.ADMIN) next()
	else if (roles.FACTORY && !to.path.includes('organizations')) next()
	else next({ name: roles.WEB_RECEPTION ? 'Reception' : 'Catalog' })
}

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: 'active-link'
})

router.beforeEach(async (to, from, next) => {
	const isWebReseption = localStorage.account && JSON.parse(localStorage.account)?.roles?.WEB_RECEPTION
	if (!localStorage.account && to.name !== 'Login') next({ name: 'Login' })
	else if (localStorage.account && to.name === 'Login') next({ name: isWebReseption ? 'Reception' : 'Catalog' })
	next()
})

export default router
