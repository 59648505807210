<template lang="pug">
.breadcrumb(v-if="route.meta && route.meta.breadcrumb")
	router-link(v-for="item in route.meta.breadcrumb" :key="item.link" :to="item.link") {{ item.title }}
</template>

<script>

import { useRoute } from 'vue-router'

export default {
	name: 'TheBreadcrumb',
	setup () {
		const route = useRoute()

		return {
			route
		}
	}
}
</script>

<style lang="scss" scoped>
.breadcrumb {
	margin-bottom: 24px;

	a {
		color: $mainColor;
		transition: color 0.3s;
		position: relative;
		margin-right: 12px;
		padding-right: 12px;

		&::before {
			content: '/';
			position: absolute;
			bottom: 1px;
			right: -3px;
		}

		&:hover {
			color: $mainColorHover;
		}
	}
}
</style>
