<template lang="pug">
div(class="title ta-c mb-4") Для вашей роли<br> веб интерфейс не предусмотрен
BaseButton(@click="logout") Выйти
</template>

<script>
import { clearToken } from '../utils/useToken'
import BaseButton from '../components/ui/BaseButton'

export default {
	name: 'TheRoleWarning',
	components: {
		BaseButton
	},
	setup () {
		const logout = async () => {
			clearToken()
			window.location.reload()
		}

		return {
			logout
		}
	}
}
</script>
