<template lang="pug">
.login.flex
	.login__side.login__side--bg.flex.flex--jce.flex--aic
		.login__logo.ta-c
			img.mb-4(:src="require('../assets/img/logo.png')")
			h2 Цифровое рыболовство
	.login__side.flex.flex--aic
		form.flex.flex--fdc(@submit.prevent)
			BaseInput.mb-4(v-model="username" placeholder="Логин")
			BaseInput.mb-4(v-model="password" placeholder="Пароль" type="password")
			BaseButton(@click="login") Войти
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import BaseInput from '../components/ui/BaseInput'
import BaseButton from '../components/ui/BaseButton'
import BaseAlert from '../components/TheAlert'

import { useAuth } from '@/modules/auth.js'

export default {
	name: 'Login',
	components: {
		BaseAlert,
		BaseInput,
		BaseButton
	},
	setup () {
		const router = useRouter()

		const username = ref('')
		const password = ref('')

		const { sendLogin, setAccount } = useAuth()

		const login = async () => {
			await sendLogin({ username: username.value, password: password.value })
			await setAccount()
			const isWebReseption = JSON.parse(localStorage.account)?.roles?.WEB_RECEPTION
			const { login } = JSON.parse(localStorage.account)

			if (login) {
				if (!isWebReseption) router.push('/catalog')
				else router.push('/reception')
			}
		}

		return {
			username,
			password,
			login
		}
	}
}
</script>

<style lang="scss" scoped>
.login {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&__logo {
		width: 120px;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 100%;
			margin-bottom: 24px;
		}

		h2 {
			color: $mainColor;
		}
	}

	@include until-desktop {
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__logo {
			margin-bottom: 32px;
		}
	}

	@include for-desktop {
		&__side {
			margin: 0;
			width: 50%;
			height: 110%;
			padding: 32px;

			&--bg {
				background-color: rgba($bgMain, 0.15);
			}
		}
	}
}
</style>
