<template lang="pug">
transition(name="show")
	.alert(v-if="show" key="alert" :class="type")
		IconPlus(close circle :bg="type" color="white" @click="$emit('update:show', false)")
		slot
</template>

<script>
import IconPlus from './ui/icons/IconPlus'

export default {
	name: 'TheAlert',
	components: { IconPlus },
	props: {
		show: {
			type: Boolean,
			required: true
		},
		type: {
			type: String,
			default: 'info',
			validator: type => ['info', 'success', 'warning', 'error'].includes(type)
		}
	},
	emits: ['update:show'],
	watch: {
		'$route.name' () {
			this.$emit('update:show', false)
		},
		show () {
			setTimeout(() => {
				this.$emit('update:show', false)
			}, 5000)
		}
	}
}
</script>

<style lang="scss" scoped>
.alert {
	font-size: 14px;
	position: fixed;
	z-index: 11;
	top: 20px;
	right: 20px;
	max-width: 360px;
	padding: 8px 12px;
	border-radius: $radiusPrimary;
	box-shadow: $shadowPrimary;

	svg {
		position: absolute;
		top: -10px;
		right: -10px;
		cursor: pointer;
		width: 20px;
		height: 20px;
	}

	&.info {
		background-color: $info;
	}

	&.error {
		background-color: $errorLight;
	}
}
</style>
