<template lang="pug">
.input(:class="[type, icon ? `input--icon input--icon-${icon}` : null, { active }]")
	input(
		autocomplete="off"
		:type="type"
		:value="modelValue"
		:disabled="disabled"
		:required="required"
		:minlength="minlength"
		:pattern="pattern"
		@input="onInput"
		@blur="$emit('blur', $event.target.value)"
	)
	span {{ placeholder }}
	component(v-if="icon" :is="`icon-${icon}`")

</template>

<script>
import { computed } from 'vue'

import IconSearch from './icons/input/IconSearch'

export default {
	name: 'BaseInput',
	components: { IconSearch },
	props: {
		modelValue: {
			type: [String, Number],
			default: ''
		},
		type: {
			type: [String, null],
			default: 'text'
		},
		placeholder: {
			type: [String, null],
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		icon: {
			type: [String, null],
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		onlyNumber: {
			type: Boolean,
			default: false
		},
		onlyText: {
			type: Boolean,
			default: false
		},
		decimalNumber: {
			type: Boolean,
			default: false
		},
		isTelephone: {
			type: Boolean,
			default: false
		},
		minlength: {
			type: [String, Number],
			default: null
		},
		pattern: {
			type: String,
			default: null
		}
	},
	emits: ['update:modelValue', 'blur'],
	setup (props, { emit }) {
		const active = computed(() => props.modelValue !== null && props.modelValue !== '')

		const prefixNumber = (str) => {
			if (str === '7') {
				return '7 ('
			}
			if (str === '8') {
				return '8 ('
			}
			if (str === '9') {
				return '7 (9'
			}
			return '7 ('
		}

		const onInput = (event) => {
			if (props.onlyNumber) {
				event.target.value = event.target.value.replace(/\D+/g, '')
				emit('update:modelValue', event.target.value)
			} else if (props.decimalNumber) {
				const val = event.target.value.replace(/[^\d.,]/g, '').replace(/\.+|,+/g, '.').match(/\d+\.?\d{0,2}/)
				event.target.value = val ? val[0] : ''
				emit('update:modelValue', event.target.value)
			} else if (props.onlyText) {
				event.target.value = event.target.value.replace(/[^А-ЯЁа-яё -]/g, '')
				emit('update:modelValue', event.target.value)
			} else if (props.isTelephone) {
				const value = event.target.value.replace(/\D+/g, '')
				const numberLength = 11

				let result
				if (event.target.value.includes('+8') || event.target.value[0] === '8') {
					result = ''
				} else {
					result = '+'
				}

				for (let i = 0; i < value.length && i < numberLength; i++) {
					switch (i) {
					case 0:
						result += prefixNumber(value[i])
						continue
					case 4:
						result += ') '
						break
					case 7:
						result += '-'
						break
					case 9:
						result += '-'
						break
					default:
						break
					}
					result += value[i]
				}

				event.target.value = result
				emit('update:modelValue', result.replace(/\D+/g, ''))
			} else {
				emit('update:modelValue', event.target.value)
			}
		}

		return {
			active,
			onInput
		}
	}
}
</script>

<style lang="scss" scoped>
.input {
	position: relative;

	input {
		outline: none;
		width: 100%;
		height: 40px;
		padding: 0 16px;
		background: $bgPrimary;
		border: 1px solid $borderPrimary;
		border-radius: $radiusPrimary;
		font-size: 16px;
		line-height: 40px;
		transition-property: border-color, box-shadow;
		transition-duration: 0.3s;

		&::placeholder {
			color: $mainColor;
		}

		&:hover,
		&:active {
			border-color: $mainColorHover;
			box-shadow: $shadowPrimary;
		}

		&:invalid {
			border: 1px solid red;
		}
	}

	span {
		pointer-events: none;
		position: absolute;
		line-height: 40px;
		top: 0;
		left: 16px;
		z-index: 2;
		color: $mainColor;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -4px;
			right: -4px;
			bottom: 0;
			background-color: $bgPrimary;
			border-radius: $radiusPrimaryHalf;
			z-index: -1;
			display: none;
		}
	}

	&--icon {
		position: relative;

		svg {
			position: absolute;
			top: 10px;
			right: 12px;
			width: 20px;
			height: 20px;
			pointer-events: none;
		}
	}

	&.password {
		input {
			-webkit-text-security: disc;
		}
	}

	&.date,
	&.datetime-local {
		input {
			color: $mainColor;

			&::-webkit-calendar-picker-indicator {
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23458EAF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
			}
		}

		&.active {
			input {
				color: $colorPrimary;
			}
		}
	}

	&.date,
	&.datetime-local,
	&.active {
		span {
			transform-origin: top left;
			transform: scale(0.7) translateY(-9px);
			line-height: 20px;

			&::before {
				display: block;
			}
		}
	}
}
</style>
