import { store } from '../../store'

export const useAlert = () => {
	const infoAlert = (title) => {
		store.commit('setAlert', {
			type: 'info',
			title
		})
	}
	const errorAlert = (title) => {
		store.commit('setAlert', {
			type: 'error',
			title
		})
	}

	return {
		infoAlert,
		errorAlert
	}
}
