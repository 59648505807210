<template lang="pug">
button.button(:class="[view, {disabled}, icon ? `button--icon button--icon-${icon}` : null]")
	slot
	.icon-btn(v-if="icon")
		component(:is="`icon-${icon}`")
</template>

<script>
import IconTrash from './icons/IconTrash'
import IconPen from './icons/button/IconPen'

export default {
	name: 'BaseButton',
	components: { IconTrash, IconPen },
	props: {
		view: {
			type: String,
			default: 'default'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		icon: {
			type: [String, null],
			default: null
		}
	}
}
</script>

<style lang="scss" scoped>
.button {
	width: fit-content;
	padding: 0 16px;
	font-size: 16px;
	line-height: 40px;
	height: fit-content;
	color: $colorInverse;
	border: none;
	border-radius: $radiusPrimary;
	cursor: pointer;
	transition-property: background-color, box-shadow, opacity;
	transition-duration: 0.3s;

	&:hover {
		box-shadow: $shadowPrimary;
	}

	&.default {
		background-color: $bgMain;

		&:hover {
			background-color: $mainColorHover;
		}
	}

	&.danger {
		background-color: $error;

		&:hover {
			background-color: rgba($error, 0.8);
		}
	}

	&.button--icon {
		background-color: transparent;
		box-shadow: none;

		&.default {
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}
		}

		&.danger {
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}
		}
	}

	&--icon {
		position: relative;
		display: block;
		&:hover {
			transform: scale(1.05);
		}
		&:active {
			transform: scale(0.95);
		}
		svg {
			display: block;
			position: absolute;
			top: 25%;
			right: 25%;
			width: 20px;
			height: 20px;
			pointer-events: none;
			color: $bgMain;
		}
	}
}

.icon-btn {
	min-width: 5px;
	min-height: 35px;
}
</style>
