<template lang="pug">
.bar.flex.flex--jcc.flex--aic
	router-link(v-for="(name, link) in links" :key="link" :to="`/${link}`")
		span {{name}}
</template>

<script>
export default {
	name: 'TheBar',
	setup () {
		const links = {}
		const isWebReseption = JSON.parse(localStorage.account)?.roles?.WEB_RECEPTION
		const { operator } = false // store.getters['account/roles']
		//
		// if (operator || admin) links.scan = 'Сканирование ящиков'
		if (!operator) {
			links.reception = 'Приёмка'
			links.events = 'События'
			links.catalog = 'Справочники'
			links.reports = 'Отчёты'
		}
		links.settings = 'Настройки'
		if (isWebReseption) {
			delete links.events
			delete links.catalog
		}
		return {
			links
		}
	}
}
</script>

<style lang="scss" scoped>
.bar {
	position: fixed;
	z-index: 4;
	bottom: 0;
	left: 0;
	right: 0;
	height: 40px;
	background-color: $bgMain;
	color: $colorInverse;
	font-size: 12px;
	text-align: center;

	&::before {
		content: '';
		position: absolute;
		top: -40px;
		left: 0;
		right: 0;
		height: 40px;
		background: linear-gradient(0, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		pointer-events: none;
	}

	a {
		padding: 10px;
		span {
			border-bottom: 1px solid transparent;
			transition: border-bottom-color 0.3s;
		}

		&.active-link {
			span {
				border-bottom-color: $bgPrimary;
			}
		}
	}

	@include for-tablet {
		a {
			padding: 10px 20px;
		}
	}
}
</style>
