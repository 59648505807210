import axios from 'axios'
import router from '@/router'
import { useAlert } from '@/modules/composition/useAlert'

const apiAuth = axios.create({
	baseURL: window.location.origin + '/api'
})

const { errorAlert } = useAlert()

export const getToken = async (username, password) => {
	console.log('get token')
	const { data } = await apiAuth.post(
		'/oauth/token',
		new URLSearchParams({
			username,
			password,
			grant_type: 'password',
			scope: 'openid'
		})
	)

	if (data) {
		setToken(data)
		return `Bearer ${data.access_token}`
	}
}

export const setToken = (response) => {
	console.log('set token')
	window.localStorage.removeItem('access_token')
	window.localStorage.removeItem('refresh_token')
	const { access_token, refresh_token } = response

	window.localStorage.setItem('access_token', `Bearer ${access_token}`)
	window.localStorage.setItem('refresh_token', refresh_token)
}

export const refreshToken = async () => {
	console.log('refresh token')
	try {
		const { data } = await apiAuth.post(
			'/oauth/token',
			new URLSearchParams({
				grant_type: 'refresh_token',
				refresh_token: window.localStorage.getItem('refresh_token')
			})
		)
		setToken(data)
	} catch (e) {
		if (e.response.status === 401) {
			await errorAlert('Необходимо заново авторизоваться')
			clearToken()
			await router.push('/')
		}
	}
}

export const clearToken = () => {
	window.localStorage.removeItem('access_token')
	window.localStorage.removeItem('refresh_token')
	window.localStorage.removeItem('account')
}
