<template>
<svg :class="['plus', {close, pointer, disabled}, bg]" :width="diameter" :height="diameter" :viewBox="`0 0 ${diameter} ${diameter}`" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle v-if="circle" :cx="radius" :cy="radius" :r="radius"/>
	<line :x1="radius" :y1="radius - 8" :x2="radius" :y2="radius + 8" :stroke="color" :stroke-width="width" stroke-linecap="round"/>
	<line :x1="radius - 8" :y1="radius" :x2="radius + 8" :y2="radius" :stroke="color" :stroke-width="width" stroke-linecap="round"/>
</svg>
</template>

<script>
export default {
	name: 'IconPlus',
	props: {
		color: {
			type: String,
			default: '#458EAF'
		},
		circle: {
			type: Boolean,
			default: false
		},
		bg: {
			type: String,
			default: 'info',
			validator: type => ['info', 'success', 'warning', 'error'].includes(type)
		},
		width: {
			type: Number,
			default: 2
		},
		close: {
			type: Boolean,
			default: false
		},
		pointer: {
			type: Boolean,
			default: true
		},
		diameter: {
			type: Number,
			default: 25
		},
		disabled: {
			type: [Boolean],
			default: false
		}
	},
	setup (props) {
		const radius = props.diameter / 2

		return {
			radius
		}
	}
}
</script>

<style lang="scss" scoped>
.plus {
	&.close {
		transform: rotate(45deg);
	}
	&.info {
		circle {
			fill: $mainColor;
		}
	}
	&.error {
		circle {
			fill: $error;
		}
	}
}
</style>
