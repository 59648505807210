<template lang="pug">
.loader
	include ../assets/img/logo.svg
</template>

<script>
export default {
	name: 'TheLoader'
}
</script>

<style lang="scss" scoped>
.loader {
	pointer-events: none;
	z-index: 10;
	position: fixed;
	top: 50px;
	left: 10px;
	width: 40px;
	height: 40px;
	background: $bgPrimary;
	padding: 8px;
	border-radius: 50%;
	box-shadow: $shadowPrimary;

	svg {
		width: 100%;
		height: 100%;
		animation: rotate 1s linear infinite;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}
</style>
