<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 404.705 404.705">
	<path d="M398.773,133.361l-21.966-5.887l9.095-10.824c1.759-2.093,2.327-4.94,1.506-7.549s-2.918-4.617-5.559-5.325L65.097,18.848
		c-2.048-0.549-4.233-0.263-6.071,0.798s-3.179,2.809-3.729,4.858L45.291,61.843c-0.189,0.409-0.353,0.836-0.474,1.286
		c-0.121,0.45-0.192,0.901-0.233,1.35l-20.413,76.177c-1.144,4.267,1.389,8.653,5.655,9.798l147.003,39.422l-30.933,116.52H94.245
		c-3.186-9.323-11.962-16.072-22.314-16.229v-9.543c0-21.133-18.328-38.327-40.855-38.327H8c-4.418,0-8,3.582-8,8V378.13
		c0,4.418,3.582,8,8,8h23.076c22.527,0,40.855-17.193,40.855-38.327v-9.72c10.151-0.154,18.792-6.645,22.128-15.687h57.991
		c3.628,0,6.802-2.441,7.732-5.947l32.501-122.428l77.815,20.868c3.813,1.023,7.703,1.542,11.562,1.542
		c9.144,0,18.005-2.912,25.584-8.293l66.708,17.875c0.681,0.183,1.377,0.272,2.07,0.272c1.393,0,2.773-0.364,4-1.072
		c1.838-1.061,3.179-2.809,3.728-4.858l20.68-77.196C405.574,138.892,403.041,134.505,398.773,133.361z M55.932,347.804
		c0,9.557-6.721,17.728-16.14,20.906v-87.08c0-4.418-3.582-8-8-8s-8,3.582-8,8v88.5H16V258.297h15.076
		c13.705,0,24.855,10.016,24.855,22.327V347.804z M71.932,322.074v-15.898c4.222,0.194,7.6,3.68,7.6,7.949
		S76.154,321.88,71.932,322.074z M274.245,199.435L41.697,137.072L58.322,75.03l247.101,66.21c0.693,0.186,1.39,0.274,2.075,0.274
		c3.532,0,6.765-2.357,7.723-5.932c1.144-4.268-1.39-8.654-5.657-9.798l-247.1-66.21l6.217-23.201l296.889,79.604l-64.003,76.165
		c-5.591,5.348-12.646,8.288-19.905,8.288C279.202,200.43,276.707,200.095,274.245,199.435z M370.366,208.486l-50.307-13.48
		l45.387-54.011l21.46,5.751L370.366,208.486z"/>
</svg>

</template>
