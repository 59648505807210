import { createStore } from 'vuex'

export const store = createStore({
	state: {
		loading: false,
		alert: {
			type: 'info',
			title: null,
			show: false
		}
	},
	mutations: {
		setLoading: (state, bool) => {
			state.loading = bool
		},
		setAlert: (state, { type, title }) => {
			state.alert.type = type
			state.alert.title = title
			state.alert.show = true
		}
	}
})
