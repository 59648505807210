<template>
  <header class="app-header">
		<div class="name" v-if="user">
			{{user.surname}} {{user.name}} {{user.middleName}}
		</div>
		<div class="organization" v-if="user.organization">
			{{user.organization.name}}
		</div>
  </header>
</template>

<script>
import { onBeforeMount, ref } from 'vue'

import { useApi } from '@/modules/api'

export default {
	name: 'TheHeader',
	setup () {
		const user = ref({})

		onBeforeMount(async () => {
			if (localStorage.account) {
				const { id } = JSON.parse(localStorage.account)
				const { get, data } = useApi(`worker/${id}`)
				await get()

				user.value = data.value
			}
		})

		return {
			user
		}
	}
}
</script>

<style lang="scss">
	.app-header {
		display: flex;
		justify-content: space-between;
		min-height: 40px;
		background-color: #458eaf;
		color: white;
		padding: 10px 20px;
	}

</style>
