<template lang="pug">
.center
	.catalog.grid.grid--2(v-if="isAdmin")
		router-link.flex.flex--fdc.flex--aic.flex--jcc.ta-c(v-for="(name, link) in catalog" :key="link" :to="`/${link}`")
			component.mb-3(:is="`icon-${link}`")
			span {{name}}
	TheRoleWarning(v-else)
</template>

<script>
import IconWorkers from '../components/ui/icons/catalog/IconWorkers'
import IconEarners from '../components/ui/icons/catalog/IconEarners'
import IconShips from '../components/ui/icons/catalog/IconShips'
import IconTransports from '../components/ui/icons/catalog/IconTransports'
import IconFactories from '../components/ui/icons/catalog/IconFactories'
import IconLicenses from '../components/ui/icons/catalog/IconLicenses'
import IconOrganizations from '../components/ui/icons/catalog/IconOrganizations'
import IconTariffs from '../components/ui/icons/catalog/IconTariffs'
import IconBoxes from '../components/ui/icons/catalog/IconBoxes'
import IconCams from '../components/ui/icons/catalog/IconCams'
import IconRegions from '../components/ui/icons/catalog/IconRegions'
import TheRoleWarning from '../components/TheRoleWarning'

export default {
	name: 'Catalog',
	components: {
		IconWorkers,
		IconEarners,
		IconShips,
		IconTransports,
		IconFactories,
		IconLicenses,
		IconOrganizations,
		IconTariffs,
		IconBoxes,
		IconCams,
		TheRoleWarning,
		IconRegions
	},
	setup () {
		const isAdmin = JSON.parse(localStorage.account).isAdmin
		const catalog = {
			workers: 'Сотрудники',
			earners: 'Добытчики',
			ships: 'Плашкоуты (водные пункты приема)',
			transports: 'ПТС',
			factories: 'Береговые пункты приема или заводы',
			regions: 'Рыболовные участки',
			licenses: 'Разрешения на добычу',
			tariffs: 'Тарифы',
			boxes: 'Ящики',
			cams: 'Видеоконтроль'
		}

		if (!JSON.parse(localStorage.account).roles.FACTORY) {
			catalog.organizations = 'Организации'
		}

		return {
			catalog,
			isAdmin
		}
	}
}
</script>

<style lang="scss" scoped>
.catalog {
	max-width: 480px;

	a {
		min-height: 120px;
		padding: 20px;
		background-color: rgba($mainColor, 0.1);
		border-radius: $radiusPrimary;
		box-shadow: $shadowPrimary;
		transition-property: transform, background-color;
		transition-duration: 0.3s;

		svg {
			display: block;
			width: 32px;
			height: 32px;
			fill: $bgMain;
		}
	}

	@include for-tablet {
		a {
			&:first-child {
				grid-column: 1 / -1;
			}

			&:hover {
				transform: scale(1.05);
				background-color: rgba($mainColor, 0.2)
			}
		}
	}
}
</style>
