import axios from 'axios'
import { useStore } from 'vuex'

import { getToken, refreshToken } from '@/utils/useToken'
import { useAlert } from '@/modules/composition/useAlert'
import { rolesAuth } from '@/modules/constants/roles'

export const useAuth = () => {
	const api = axios.create({
		baseURL: window.location.origin
	})
	const store = useStore()
	const { errorAlert } = useAlert()

	const sendLogin = async ({ username, password }) => {
		store.commit('setLoading', true, { root: true })

		try {
			await getToken(username, password)
		} catch (e) {
			if (e.response?.status === 401) {
				errorAlert('Токен недействителен. Перезагрузите страницу и повторите вход в систему')
			} else if (e.response?.data) {
				errorAlert(e.response.data?.error_description || e.response.data?.message || 'Ошибка')
			}
		} finally {
			store.commit('setLoading', false, { root: true })
		}
	}

	const setAccount = async () => {
		store.commit('setLoading', true, { root: true })

		const getAccount = async () => {
			const { data: { id, login, authorities } } = await api.get(
				'/api/account',
				{
					headers: {
						Authorization: window.localStorage.getItem('access_token')
					}
				})
			if (login) {
				const roles = {}
				let isAdmin = false
				authorities.forEach(ROLE => {
					roles[ROLE] = rolesAuth[ROLE] === null
					if (ROLE === 'ADMIN' || ROLE === 'FACTORY') isAdmin = true
				})

				localStorage.account = JSON.stringify({ id, login, roles, isAdmin })
			}
		}

		if (!localStorage.account) {
			try {
				await getAccount()
			} catch (e) {
				await refreshToken()
			} finally {
				store.commit('setLoading', false, { root: true })
			}
		}
	}

	return {
		sendLogin,
		setAccount
	}
}
